.preview-wrapper {
  width: 120px;
  height: 64px;
}

.preview-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
