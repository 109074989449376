.Spinner {
  display: inline-block;
  font-size: 8px;
  height: 8px;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.Spinner--primary > .Spinner_dot {
  background-color: #c8cfff;
}

.Spinner_dot {
  -webkit-animation: pulse 1s infinite ease-in-out;
  -o-animation: pulse 1s infinite ease-in-out;
  animation: pulse 1s infinite ease-in-out;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
}

.Spinner_dot--second {
  -webkit-animation-delay: 160ms;
  animation-delay: 160ms;
  margin-left: 1em;
}

.Spinner_dot--third {
  -webkit-animation-delay: 320ms;
  animation-delay: 320ms;
  margin-left: 1em;
}

.Spinner-text {
  width: max-content;
}

@keyframes pulse {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
